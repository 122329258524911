







import Vue from 'vue'
import Exchanges from './components/Exchanges/index.vue'
import ExchangeSumary from './components/ExchangeSummary/index.vue'

export default Vue.extend({
  components: {
    Exchanges,
    ExchangeSumary
  }
})
